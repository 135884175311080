import React, { useEffect, useState } from 'react';
import Menu from '../Menu'
import NotesList from '../NotesList';
import ClientProfiles from '../ClientProfiles';
import RecordingBanner from '../RecordingBanner'
import * as apiUtils from '../../utils/apiUtils';
import Loader from '../Loader';
function Dashboard() {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState("all")

  const getNotesList = async () => {
    try {
      setLoading(true)
      const data = await apiUtils.get('/api/notes/');
      console.log('API Response:', data);
      setNotes(data.results)
    } catch (error) {
      console.error('getUserSettings.error', error)
    } finally {
      setLoading(false)
    }
  }
  const handleClientFilter = (id) => {
    if (id === "all") {
      setFilterData(notes);
      return;
    }

    const filteredData = notes?.filter((item) => item?.client_data?.id === id);
    setFilterData(filteredData);
  }
  
  useEffect(() => {
    getNotesList()
  }, []);


  return (
    <>
      <Menu isPlanActive={true} />
      {loading ? (
        <Loader />
      ) : (<><ClientProfiles clientFilter={handleClientFilter} />
        <NotesList notes={notes} filterData={filterData} />
        <RecordingBanner /></>)}
    </>
  );
}

export default Dashboard;